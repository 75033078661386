import React, { useEffect, useState } from "react";
import "./slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import slide_img from "../../images/Group 28.png";
import border from "../../images/border.png";
import { FaRegHeart } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import { IoStar } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../constant";
import { toast } from "react-toastify";
import img5 from "../../images/Rectangle5.png";
import { FaHeart } from "react-icons/fa6";
import { useCartContext } from "../../Context/CartContext";

const Slider = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);
  const { cartCount, fetchCartCount } = useCartContext();

  useEffect(() => {
    fetchCartItems();
    fetchWishlistItems();
    fetchProducts();
    fetchCartCount();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/products/allProductList`);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Function to fetch cart items
  const fetchCartItems = async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const response = await axios.post(
          `${BASE_URL}/usercart/in-cart?userId=${userId}&pageSize=50`
        );
        setCartItems(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    }
  };

  // Function to fetch wishlist items
  const fetchWishlistItems = async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const response = await axios.get(`${BASE_URL}/wishlist/get/${userId}`);
        setWishlistItems(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    }
  };

  const handleAddToCart = async (productId) => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const cartResponse = await axios.post(
          `${BASE_URL}/usercart/add-product?userId=${userId}&productId=${productId}&orderQuantity=1`
        );
        await fetchCartCount();
        toast.success("Product added to cart");
        fetchCartItems();
      } catch (error) {
        console.error("Error adding product to cart:", error);
        toast.error("Something went wrong! Try again later");
      }
    } else {
      navigate("/login");
    }
  };

  const handleAddToWishlist = async (productId) => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const wishlistResponse = await fetch(
          `${BASE_URL}/wishlist/add/${userId}`,
          {
            method: "POST",
            headers: {
              contentType: "application/json",
            },
            body: productId,
          }
        );
        console.log(productId);

        console.log(wishlistResponse.data);

        toast.success("Product added to wishlist");
        fetchWishlistItems();
      } catch (error) {
        console.error("Error adding product to wishlist:", error);
        toast.error("Something went wrong! Try again later");
      }
    } else {
      navigate("/login");
    }
  };

  const removeWishlistItem = async (productId) => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.delete(
        `${BASE_URL}/wishlist/delete/${userId}/${productId}`
      );
      console.log(response);
      toast.error("Product removed from wishist");
    } catch (error) {
      console.error("Error removing product from wishlist:", error);
      toast.error("Something went wrong! Try again later");
    }
  };

  const renderHeading = () => {
    if (location.pathname === "/") {
      return <h1>Sacred Offerings</h1>;
    } else {
      return <h1>Related Products</h1>;
    }
  };

  return (
    <div>
      <div className="headings" style={{ paddingTop: "60px" }}>
        {renderHeading()}
        <img src={border} width={"350px"} alt="img" />
      </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 110,
          modifier: 2.5,
          slideShadows: false,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
        initialSlide={2}
      >
        {products.slice(0, 5).map((product) => {
          const firstPhotoUrl =
            product.productPhotos && product.productPhotos.length > 0
              ? product.productPhotos[0].photoName
              : img5;
          return (
            <SwiperSlide key={product.productId} className="img-slide">
              <Card className="card">
                <Card.Img
                  variant="top"
                  className="card-img"
                  src={firstPhotoUrl}
                  onClick={() =>
                    navigate(`/productdetails/${product.productId}`)
                  }
                />
                <Card.Body className="card-content">
                  <h5
                    onClick={() =>
                      navigate(`/productdetails/${product.productId}`)
                    }
                  >
                    {product.productName}
                  </h5>
                  <p
                    onClick={() =>
                      navigate(`/productdetails/${product.productId}`)
                    }
                  >
                    {product.prdDesc?.slice(0, 50)}...
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      {[...Array(5)].map((_, index) => (
                        <IoStar
                          key={index}
                          style={{ color: "rgb(217, 189, 8)" }}
                        />
                      ))}
                      (450)
                    </div>
                    <span style={{ marginRight: "10px" }}>
                      <p>
                        <span
                          style={{
                            color: "gray",
                            textDecoration: "line-through",
                            fontSize: "16px",
                          }}
                        >
                          Rs {product.mrp}
                        </span>{" "}
                        Rs {product.purRate.toFixed(2)}
                      </p>{" "}
                    </span>
                  </div>
                  {product.productQuantity > 0 ? (
                    cartItems.some(
                      (item) => item.productid === product.productId
                    ) ? (
                      <button className="added-cart">Added to Cart</button>
                    ) : (
                      <button
                        className="cart"
                        onClick={() => handleAddToCart(product.productId)}
                      >
                        Add to Cart
                      </button>
                    )
                  ) : (
                    <button className="cart" disabled>
                      Add to cart
                    </button>
                  )}
                  {wishlistItems.some(
                    (item) => item.productId === product.productId
                  ) ? (
                    <button
                      className="wishlist"
                      onClick={() => removeWishlistItem(product.productId)}
                    >
                      <FaHeart size={25} style={{ color: "red" }} />
                    </button>
                  ) : (
                    <button
                      className="wishlist"
                      onClick={() => {
                        handleAddToWishlist(product.productId);
                        console.log(product.productId);
                      }}
                    >
                      <FaRegHeart size={25} />
                    </button>
                  )}
                </Card.Body>
              </Card>
            </SwiperSlide>
          );
        })}

        <div className="slider_controller">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
        </div>
      </Swiper>
    </div>
  );
};

export default Slider;
