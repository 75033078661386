import React, { useEffect, useState } from "react";
import img1 from "../../images/Group 28.png";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import "./CheckoutPage.css";
import axios from "axios";
import { BASE_URL } from "../../constant";
import { toast } from "react-toastify";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("razorPay");

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [allAddresses, setAllAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({
    addrId: 1,
    name: "",
    houseName: "",
    street: "",
    landMark: "",
    city: "",
    state: "",
    pinCode: "",
    countryCode: "",
    mobile: "",
    country: "",
  });
  const [addressErrors, setAddressErrors] = useState({});

  useEffect(() => {
    fetchAddresses();
    fetchCartItems();
  }, []);

  // to  handle the payment method selection one at a time
  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleAddressChange = (address) => {
    setSelectedAddress(address);
  };

  const fetchAddresses = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(
        `${BASE_URL}/users/getAllAddresses/${userId}`
      );
      if (response.data && response.data.length > 0) {
        setAllAddresses(response.data);
        setSelectedAddress(response.data[0]);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  const fetchCartItems = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (userId) {
        const response = await axios.post(
          `${BASE_URL}/usercart/in-cart?userId=${userId}&pageSize=50`
        );
        setCartItems(response.data);
        console.log(response.data);
        // Calculate subtotal
        let subtotal = 0;

        response.data.forEach((item) => {
          subtotal += item.productRate * item.orderQuantity;
        });
        setSubtotal(subtotal);
        setTotal(subtotal + 10);
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };
  const placeOrder = async () => {
    try {
      if (allAddresses.length === 0) {
        toast.error("Please add a shipping address before placing the order.");
        return;
      }

      const userId = localStorage.getItem("userId");

      const { addrId } = selectedAddress;
      const paymentMethod = selectedPaymentMethod;

      // Define discount variable
      let discount = 0;

      // Update total amount if a coupon is applied
      let orderAmount = total; // Initialize with total

      if (couponApplied) {
        try {
          const response = await fetch(
            `${BASE_URL}/api/coupons/name/${coupon}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Failed to fetch coupon: ${errorData.message}`);
            toast.error("Coupon code invalid");
            return;
          }

          const couponData = await response.json();

          if (couponData.couponType === "%") {
            // If the coupon is a percentage discount, calculate the discount based on the subtotal
            discount = subtotal * (couponData.couponValue / 100);
          } else if (couponData.couponType === "Rs") {
            // If the coupon is a fixed amount discount, subtract it directly from the subtotal
            discount = couponData.couponValue;
          }

          orderAmount = subtotal - discount;
        } catch (error) {
          console.error("Error in API request:", error);
        }
      }

      // Update the state with the new total and discount
      setTotal(orderAmount + 10); // Add delivery charge
      setCouponApplied(true);

      const response = await axios.post(
        `${BASE_URL}/usercart/finalize?userId=${userId}&finalOrderAmount=${orderAmount}&addrId=${addrId}&paymentMethod=${paymentMethod}`
      );

      console.log("Order placed successfully:", response.data);
      navigate("/payment-successful");
    } catch (error) {
      console.error("Error placing order:", error);
      navigate("/payment-unsuccessful");
    }
  };

  const handleAddAddress = async (event) => {
    event.preventDefault();

    console.log("New Address:", newAddress);

    setAddressErrors((prevErrors) => ({
      ...prevErrors,
      allFields: "",
    }));

    if (
      !newAddress.name ||
      !newAddress.houseName ||
      !newAddress.street ||
      !newAddress.landMark ||
      !newAddress.city ||
      !newAddress.state ||
      !newAddress.pinCode ||
      !newAddress.country ||
      !newAddress.countryCode ||
      !newAddress.mobile
    ) {
      setAddressErrors({
        allFields: "Please fill in all required fields.",
      });
      return;
    }
    // Validation for pin code
    if (!/^\d{6}$/.test(newAddress.pinCode)) {
      setAddressErrors((prevErrors) => ({
        ...prevErrors,
        pinCode: "Please enter a valid 6-digit pin code.",
      }));
      return;
    }

    // Validation for mobile number
    if (!/^\d{10}$/.test(newAddress.mobile)) {
      setAddressErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Please enter a valid 10-digit mobile number.",
      }));
      return;
    }

    try {
      const userId = localStorage.getItem("userId");

      // Fetching user's existing addresses
      const fetchResponse = await axios.get(
        `${BASE_URL}/users/getAllAddresses/${userId}`
      );
      const userAddresses = fetchResponse.data;

      let newAddressData;

      const existingAddrIds = userAddresses.map((address) => address.addrId);
      let newAddrId;
      for (let i = 1; i <= 3; i++) {
        if (!existingAddrIds.includes(i.toString())) {
          newAddrId = i;
          break;
        }
      }

      if (!newAddrId) {
        toast.error("You can't add more than three addresses.");
        return;
      }

      newAddressData = {
        ...newAddress,
        addrId: newAddrId.toString(),
      };

      console.log("Sending data to backend:", newAddressData);

      const addressRes = await axios.post(
        `${BASE_URL}/users/addNewAddress/${userId}`,
        newAddressData
      );

      console.log("Response from backend:", addressRes.data);

      fetchAddresses();

      if (addressRes && addressRes.data) {
        toast.success("Address added successfully!");
      }
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("Error adding address. Please try again.");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  // handle coupon application
  const handleApplyCoupon = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/api/coupons/name/${coupon}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error(`Failed to fetch coupon: ${errorData.message}`);
        toast.error("Coupon code invalid");
        return;
      }

      const couponData = await response.json();

      // Apply the coupon to the cart
      let discount = 0;
      if (couponData.couponType === "%") {
        // If the coupon is a percentage discount, calculate the discount based on the total amount
        discount = subtotal * (couponData.couponValue / 100);
      } else if (couponData.couponType === "Rs") {
        // If the coupon is a fixed amount discount, subtract it directly from the total amount
        discount = couponData.couponValue;
      }

      // Calculate the new total after applying the coupon
      const newTotal = subtotal - discount;

      // Update the UI with the new total and apply coupon message
      setTotal(newTotal);
      setCouponApplied(true);
      toast.success("Coupon applied successfully");
    } catch (error) {
      console.error("Error in API request:", error);
    }
  };

  return (
    <div className="checkout-page">
      <Header />
      <h1>Checkout</h1>
      <div className="order-summary">
        <div className="order-item-container">
          <h3>Order Summary</h3>
          {cartItems.length > 0
            ? cartItems.map((item) => (
                <React.Fragment>
                  <div className="order-item" key={item.productId}>
                    <img
                      src={item.productImage ? item.productImage : img1}
                      loading="lazy"
                    />
                    <h4>
                      {item.productName} -
                      <span>{item.productDescription?.slice(0, 30)}...</span>
                    </h4>
                    <p>Rs {item.productRate * item.orderQuantity}</p>
                  </div>
                </React.Fragment>
              ))
            : ""}
          <button onClick={() => navigate("/shop")}>
            <FaPlus /> Add More Products
          </button>
        </div>

        <div className="order-item-price">
          <table>
            <tbody>
              <tr>
                <td className="bold">Items {`(${cartItems.length})`}</td>
                <td>Rs {subtotal.toFixed(2)}</td>
              </tr>
              <tr>
                <td className="bold">Subtotal</td>
                <td>Rs {subtotal.toFixed(2)}</td>
              </tr>
              <tr>
                <td className="bold">Delivery charge</td>
                <td>Rs 10.00</td>
              </tr>
              <tr>
                <td className="bold">Total</td>
                <td>Rs {total.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="shipping-address-container">
        <div className="container-2">
          <div className="shipping-address-form">
            <h3>Delivery Address</h3>
            {addressErrors.allFields && (
              <span className="error">{addressErrors.allFields}</span>
            )}
            <form className="shipping-form">
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={newAddress.name}
                onChange={handleInputChange}
                className={
                  addressErrors.name ||
                  (!newAddress.name && addressErrors.allFields)
                    ? "error-border"
                    : ""
                }
                required
              />
              <input
                type="text"
                placeholder="House No"
                name="houseName"
                value={newAddress.houseName}
                onChange={handleInputChange}
                className={
                  addressErrors.houseName ||
                  (!newAddress.houseName && addressErrors.allFields)
                    ? "error-border"
                    : ""
                }
                required
              />
              <input
                type="text"
                placeholder="Street"
                name="street"
                value={newAddress.street}
                onChange={handleInputChange}
                className={
                  addressErrors.street ||
                  (!newAddress.street && addressErrors.allFields)
                    ? "error-border"
                    : ""
                }
                required
              />
              <input
                type="text"
                placeholder="Apartment,suite,etc"
                name="landMark"
                value={newAddress.landMark}
                onChange={handleInputChange}
                className={
                  addressErrors.landMark ||
                  (!newAddress.landMark && addressErrors.allFields)
                    ? "error-border"
                    : ""
                }
                required
              />
              {addressErrors.pinCode && (
                <span className="error">{addressErrors.pinCode}</span>
              )}
              <div id="add-info">
                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  value={newAddress.city}
                  onChange={handleInputChange}
                  className={
                    addressErrors.city ||
                    (!newAddress.city && addressErrors.allFields)
                      ? "error-border"
                      : ""
                  }
                  required
                />
                <input
                  type="text"
                  placeholder="State"
                  name="state"
                  value={newAddress.state}
                  onChange={handleInputChange}
                  className={
                    addressErrors.state ||
                    (!newAddress.state && addressErrors.allFields)
                      ? "error-border"
                      : ""
                  }
                  required
                />
                <input
                  type="text"
                  placeholder="Pincode"
                  name="pinCode"
                  value={newAddress.pinCode}
                  onChange={handleInputChange}
                  className={
                    addressErrors.pinCode ||
                    (!newAddress.pinCode && addressErrors.allFields)
                      ? "error-border"
                      : ""
                  }
                  required
                />
              </div>
              <input
                type="text"
                placeholder="Country"
                name="country"
                value={newAddress.country}
                onChange={handleInputChange}
                className={
                  addressErrors.country ||
                  (!newAddress.country && addressErrors.allFields)
                    ? "error-border"
                    : ""
                }
                required
              />
              {addressErrors.mobile && (
                <span className="error">{addressErrors.mobile}</span>
              )}
              <div id="add-info">
                <input
                  type="text"
                  placeholder="CountryCode"
                  name="countryCode"
                  value={newAddress.countryCode}
                  onChange={handleInputChange}
                  className={
                    addressErrors.countryCode ||
                    (!newAddress.countryCode && addressErrors.allFields)
                      ? "error-border"
                      : ""
                  }
                  required
                />

                <input
                  type="text"
                  placeholder="Phone number"
                  name="mobile"
                  value={newAddress.mobile}
                  onChange={handleInputChange}
                  className={
                    addressErrors.mobile ||
                    (!newAddress.mobile && addressErrors.allFields)
                      ? "error-border"
                      : ""
                  }
                  required
                />
              </div>

              <div id="save-checkbox">
                <input type="checkbox" />
                <label>Save this information for next time</label>
              </div>
              {allAddresses.map((address, index) => (
                <div className="additional-address" key={index}>
                  <input
                    type="radio"
                    id="radio"
                    checked={address === selectedAddress}
                    onChange={() => handleAddressChange(address)}
                  />
                  <p>
                    {address.name} {address.houseName}, {address.landMark},
                    {address.street} {address.city},{address.state},{" "}
                    {address.pinCode}, {address.country}
                  </p>
                </div>
              ))}
              <div id="save-btn">
                <button type="submit" onClick={handleAddAddress}>
                  Save
                </button>
              </div>
            </form>
          </div>
          <div>
            <div className="payment-method-container">
              <h3>Select Payment Method</h3>
              <div className="payment-methods">
                <div className="payment-method">
                  <div id="razorpay">
                    <input
                      type="radio"
                      value={"razorPay"}
                      checked={selectedPaymentMethod === "razorPay"}
                      onChange={handlePaymentMethodChange}
                    />
                    <label>Razor Pay</label>
                  </div>
                </div>
                <div className="payment-method">
                  <div id="cash">
                    <input
                      type="radio"
                      value={"COD"}
                      checked={selectedPaymentMethod === "COD"}
                      onChange={handlePaymentMethodChange}
                    />
                    <label>Cash on Delivery </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="coupon-container">
              <h3>Use Coupon Code</h3>
              <div className="coupon-box">
                <form>
                  <label>Enter coupon code if you have one.</label>
                  <br />
                  <input
                    type="text"
                    name="couponName"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                  />
                </form>
                <button
                  type="submit"
                  onClick={handleApplyCoupon}
                  disabled={couponApplied}
                >
                  Apply Coupon
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="checkout-btn">
          <button type="submit" onClick={placeOrder}>
            Checkout
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CheckoutPage;
